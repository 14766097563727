import React from "react"
import alumData from "../../data/alumni/alumniData.json"
import * as styles from "./members.module.css"
import Layout from "../components/layout.js"
import Footer from "../components/footer.js"
import Link from "gatsby-link"
import Helmet from "../components/meta.js"

const alumList = [
  {
    year: "2023",
    members: [
      "Rahul Ramdev",
      "Anant Choudhary",
      "Vaibhav Singh",
      "Yatharth Jain",
      "Arnav Narang",
      "Raghav Grover",
      "Divyansh Khurana",
      "Prabhnoor Kaur"
    ]
  },
  {
    year: "2022",
    members: [
      "Manan",
      "Taran Chadha",
      "Gaurika Agarwal",
      "Karmanya Bhalla",
      "Ayaan Choudhury",
      "Adarsh Bhatt",
      "Vansh Sachdeva"
    ]
  },
  {
    year: "2021",
    members: [
      "Hitarth Khurana",
      "Inesh Tickoo",
      "Tarush Sonakya",
      "Neel Lodha",
      "Kartik K Sharma",
      "Mehardeep Kaur",
      "Tejas Anand",
      "Vihaan Sarin"
    ]
  },
  {
    year: "2020",
    members: [
      "Shobhit Rathi",
      "Alankrita Ghosh"
    ]
  },
  {
    year: "2019",
    members: [
      "Raghav Puri",
      "Tapish Golcha",
      "Shaurya Nathan",
      "Abhigya Anand",
      "Khush Malik",
      "Vedansh Arun",
      "Sidhya Tikku",
      "Sanya Verma",
      "Kunal Bhatia",
    ],
  },
  {
    year: "2018",
    members: [
      "Jotsaroop Singh",
      "Harshit Bhasin",
      "Shivansh Anand",
      "Divyaspandan Misra",
      "Rushil Gulyani",
      "Pushkal Sethi",
    ],
  },
  {
    year: "2017",
    members: [
      "Jaskirat Gujral",
      "Yogesh Aggarwal",
      "Gauri Batra",
      "Shivang Saigal",
      "Priyank Kumar",
      "Tanvish Jha",
      "Aditya Rathore",
      "Pragyan Mehrotra",
      "Pratyush Aggarwal",
    ],
  },
  {
    year: "2016",
    members: [
      "Sidhant Bansal",
      "Anubhav Baweja ",
      "Agastya Jha ",
      "Devang Sehgal",
      "Umesh Meena ",
      "Arpit Singh Ahluwalia",
      "Parth Sharma",
      "Karan Kapur",
      "Arshdeep Singh Kawatra ",
      "Kabiir Krishna",
      "Neeraj Pandey ",
      "Vivek Gupta",
    ],
  },
  {
    year: "2015",
    members: [
      "Bhuvesh Mehendiratta",
      "Rohan Kumar",
      " Rajat De Pro",
      "Sudhanshu Gautam",
      "Kanishk Razdan",
      "Aayush Aggarwal",
      "Aman Arora",
      "Divyansh Sharma",
      "Kartik Ahuja",
      "Additya Popli",
      "Japneet Singh ",
      "Shantanu Kumar",
    ],
  },
  {
    year: "2014",
    members: [
      "Arul Ajmani ",
      "Sahil Chadha ",
      "Nischay Negi ",
      "Jaskirat Oberoi",
      "Daksh Anand",
      "Sarthak Raheja ",
      "Priyanshu Pandey",
      "Saksham Soni",
    ],
  },
  {
    year: "2013",
    members: [
      "Aaryaman Chakraborty ",
      "Navendu Jha",
      "Pranav Dhingra ",
      "Kapil Kalra",
      " Yash Tewari",
      "Hardik Kapoor",
      "Sandeep Behera",
      "Sanjam Kumar ",
      "Suumil Roy",
      " Virat Chopra ",
      "Aryan Juneja",
    ],
  },
  {
    year: "2012",
    members: [
      "Anunay Kulshrestha",
      "Ojasvi Trikha",
      "Krishna Gupta ",
      "Sanchit Shorewala ",
      "Dhruv Chadha",
      "Aniruddha Bajaj",
      "Apurv Agarwal",
      "Anmol Sood",
      " Anurag Sharma",
    ],
  },
  {
    year: "2011",
    members: [
      "Sopandev Tewari ",
      "Daksh Sharma",
      "Shashank Bhardwaj ",
      "Abhinav Kumar ",
      "Sankalp Sethi",
      "Vignesh Kishan",
      " Rahul Mittal",
    ],
  },
  {
    year: "2010",
    members: [
      "Shubham Bhatia ",
      "Ashwin Buti",
      "Rajat Kulshreshtha",
      "Saksham Sahai",
      "Arastu Kumar",
      "Ankit Arora",
      "Aditya Shailaj ",
      "Ananya Harsh",
    ],
  },
  {
    year: "2009",
    members: [
      "Ravi Punj",
      "Aditya Chitral",
      "Anirudh Garg",
      "Kavneet Singh Rekhi",
      "Akul Sareen",
      "Anish Sareen ",
      "Utkarsha Bhardwaj",
      "Suraj Kaul",
      "Siddharth Batra ",
      "Vardaan Sharma",
      "Karan Sahni",
    ],
  },
  {
    year: "2008",
    members: [
      "Rachit Bhatnagar",
      "Rupin Kumar",
      "Akhil Lohchab",
      "Malay Keshav",
      "Shrey Malhotra",
    ],
  },
  {
    year: "2007",
    members: [
      "Ankit Buti",
      "Shitij Nigam",
      "Nipun Kumar",
      "Doyen Sahoo",
      "Siddhant Khanna",
      "R. Sudarshan",
      "Tejas Kapoor",
    ],
  },
  { year: "2006", members: ["Jyotaditya Singh", "Mubeen Musaid"] },
]

export default function alumFull() {
  return (
    <div>
      <Helmet></Helmet>
      <Layout>
        <div>
          {alumList.map(lol => (
            <div>
              <h1>{lol.year}</h1>
              <div
                style={{
                  marginTop: `1.4rem`,
                  marginBottom: `1.4rem`,
                  width: `90%`,
                  display: `flex`,
                  flexWrap: `wrap`,
                }}
              >
                {lol.members.map(alum => (
                  <div
                    style={{
                      textAlign: `center`,
                      padding: `20px`,
                      paddingTop: `40px`,
                      paddingBottom: `40px`,
                      margin: `10px`,
                      border: `2px solid #f2622710`,
                      borderRadius: `25px`,
                      width: `150px`,
                      display: `inline-block`,
                    }}
                  >
                    <h2
                      style={{
                        color: `#f26227`,
                        fontWeight: `600`,
                        fontSize: `1rem`,
                      }}
                    >
                      {alum}
                    </h2>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </Layout>
      <Footer />
    </div>
  )
}
